const styles = {
  imageContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "1250px",
    height: "400px",
    marginTop: "-200px" /* Half height */,
    marginLeft: "-625px" /* Half width */,
  },
};

export default styles;
