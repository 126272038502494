import React, { Component } from "react";
import { auth, handleCheckUser } from "services/firebase";
import { Login, AlertDialog, Loading } from "components";

class LoginContainer extends Component {
  state = {
    email: "",
    password: "",
    isOpenAlert: false,
    isLoading: true,
  };

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.checkUser(user);
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  checkUser = (user) => {
    if (handleCheckUser(user)) {
      this.props.history.push("/app");
    } else {
      this.setState({
        isOpenAlert: true,
        isLoading: false,
      });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    // console.log("Submitted form:");
    // console.log(`Email: ${this.state.email}`);
    // console.log(`Password: ${this.state.password}`);
    try {
      const res = await auth.signInWithEmailAndPassword(
        this.state.email,
        this.state.password
      );
      this.checkUser(res.user);
    } catch (err) {
      // console.log(err);
      this.setState({
        isOpenAlert: true,
        isLoading: false,
      });
    }
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleCloseAlert = () => {
    this.setState({
      isOpenAlert: false,
    });
  };

  render() {
    return (
      <div>
        <Login
          email={this.state.email}
          password={this.state.password}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        />
        <AlertDialog
          isOpenAlert={this.state.isOpenAlert}
          onCloseAlert={this.handleCloseAlert}
          title="Login Failed"
          description="Invalid email and/or password!"
        />
        <Loading open={this.state.isLoading} />
      </div>
    );
  }
}

export default LoginContainer;
