import app from "firebase/app";
import "firebase/auth";
import "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

const fireapp = app.initializeApp(firebaseConfig);

export const auth = fireapp.auth();
export const func = fireapp.functions("asia-east2");

export function handleCheckUser(user) {
  if (user.uid !== process.env.REACT_APP_FB_ACCEPTED_UID) {
    auth.signOut();
    return false;
  } else {
    return true;
  }
}

export default app;
