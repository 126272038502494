function randomInRange(start, end) {
  return Math.floor(Math.random() * (end - start + 1) + start);
}

function toBaseN(num, base) {
  if (num === 0) {
    return "0";
  }
  var digits =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var len = Math.min(digits.length, base);
  var result = "";
  while (num > 0) {
    result = digits[num % len] + result;
    num = parseInt(num / len, 10);
  }

  return result;
}

function fromBaseN(str, base) {
  if (str === null || str.length === 0) {
    return 0;
  }
  var digits =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  // var len = Math.min(digits.length, base);
  var result = 0;
  for (var i = 0; i < str.length; i++) {
    var p = digits.indexOf(str[i]);
    if (p < 0 || p >= base) {
      return NaN;
    }
    result += p * Math.pow(digits.length, str.length - i - 1);
  }
  return result;
}

export function generatePassword() {
  // Generate password
  const cap = randomInRange(36, 61);
  const capIndex = randomInRange(0, 17);
  const num = randomInRange(0, 9);
  const numIndex = randomInRange(0, 17);

  const passwordArr10 = [];
  for (let i = 0; i < 18; i++) {
    passwordArr10.push(randomInRange(10, 35));
  }
  passwordArr10[capIndex] = cap;
  passwordArr10[numIndex] = num;

  let passwordArr62 = [];
  passwordArr62 = passwordArr10.map((p) => toBaseN(p, 62));

  const p1 = passwordArr62.splice(0, 6);
  const p2 = passwordArr62.splice(0, 6);
  const p3 = passwordArr62.splice(0, 6);

  return `${p1.join("")}-${p2.join("")}-${p3.join("")}`;
}
