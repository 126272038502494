import React, { Component } from "react";
import { auth, handleCheckUser } from "services/firebase";
import { generatePassword } from "utils/password";
import { App, AlertDialog, Loading } from "components";

class AppContainer extends Component {
  state = {
    password: "password",
    isOpenAlert: false,
    titleAlert: "",
    descAlert: "",
    isLoading: true,
  };

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      this.checkUser(user);
      this.handleRegenerate();
    });
  }

  checkUser = (user) => {
    if (user === null || !handleCheckUser(user)) {
      this.props.history.push("/");
    } else {
      this.setState({ isLoading: false });
      this.user = user;
    }
  };

  handleCopyToClipboard = () => {
    navigator.clipboard.writeText(this.state.password);
    this.handleOpenAlert(
      "Operation Success",
      "The secure password is right now in your clipboard. Please make sure to paste it in appropriate location."
    );
  };

  handleRegenerate = () => {
    // this.setState({ isLoading: true });
    try {
      const res = generatePassword();

      this.setState({
        password: res,
      });
    } catch (err) {
      this.handleOpenAlert(
        "Generate Failed",
        "Either server has issue or you are not authorized to access this thing"
      );
    }
  };

  handleLogout = () => {
    auth.signOut();
  };

  handleOpenAlert = (title, description) => {
    this.setState({
      isOpenAlert: true,
      titleAlert: title,
      descAlert: description,
    });
  };

  handleCloseAlert = () => {
    this.setState({
      isOpenAlert: false,
    });
  };

  svcGeneratePassword() { }

  render() {
    return (
      <div>
        <App
          password={this.state.password}
          onCopyToClipboard={this.handleCopyToClipboard}
          onRegenerate={this.handleRegenerate}
          onLogout={this.handleLogout}
        />
        <AlertDialog
          isOpenAlert={this.state.isOpenAlert}
          onCloseAlert={this.handleCloseAlert}
          title={this.state.titleAlert}
          description={this.state.descAlert}
        />
        <Loading open={this.state.isLoading} />
      </div>
    );
  }
}

export default AppContainer;
